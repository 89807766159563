import { InvoiceFacetSearchData, InvoiceSearchData } from './../../../model/smallPayloadModels/invoiceSearchData';
import { ActionTree } from 'vuex';
import { InvoiceState } from './types';
import { RootState } from '../../types';
import { defaultBackendInvoice } from '@/shared/backend/invoice';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import invoice, { Value, Invoice } from '@/shared/model/invoice';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';

const logger = new Logger('actions.invoices');
export const actions: ActionTree<InvoiceState, RootState> = {
  getInvoices({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; invoiceSearchData?: any }) {
    commit('setInvoicesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getInvoicesSearchParams;
    let invoiceSearchData = payload?.invoiceSearchData ?? {};
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendInvoice
      .getInvoices(searchParams, invoiceSearchData)
      .then((response: AxiosResponse<Value>) => {
        let suppliersInfo = rootGetters['auth/supplierTooltipInfo'];
        //senderNumber
        // console.log('response.data :>> ', response.data);
        if (suppliersInfo) {
          // ONLY_DEV (GSP-117) for now loading tooltip only for dev
          response.data.value = response.data.value.map((item: any) =>
            Object.assign(
              item,
              suppliersInfo.find((y: any) => y.zrNummer == item.zr_nr_kreditor)
            )
          );
        }

        //  console.log('response.data after :>> ', response.data);
        commit('setInvoices', response.data);
        commit('setInvoicesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setInvoicesIsLoading', false);
        module_utils.error('getInvoices error', commit, e, logger);
        throw e;
      });
  },

  getInvoicesFacetSearch({ commit, dispatch }, payload: InvoiceFacetSearchData) {
    return defaultBackendInvoice
      .getInvoiceFacetSearch(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getInvoiceFacetSearch response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        return result;
      })
      .catch((e: any) => {
        module_utils.error('getInvoicesFacet error', commit, e, logger);
        throw e;
      });
  },

  getInvoice({ commit, dispatch, rootGetters }, id: string) {
    let suppliersInfo = rootGetters['auth/supplierTooltipInfo'];

    return defaultBackendInvoice
      .getInvoice(id)
      .then((response: AxiosResponse<Invoice>) => {
        if (suppliersInfo) {
          // ONLY_DEV (GSP-117) for now loading tooltip only for dev
          response.data = Object.assign(
            response.data,
            suppliersInfo.find((y: any) => y.zrNummer == response.data.zr_nr_kreditor)
          );
        }

        return response?.data;
      })
      .catch((e: any) => {
        module_utils.error('getInvoice error', commit, e, logger);
        throw e;
      });
  },
  getInvoiceByDocNumber({ commit, dispatch, rootGetters }, document_number: string) {
    let suppliersInfo = rootGetters['auth/supplierTooltipInfo'];
    return defaultBackendInvoice
      .getInvoiceByDocNumber(document_number)
      .then((response: AxiosResponse<Invoice>) => {
        if (suppliersInfo) {
          // ONLY_DEV (GSP-117) for now loading tooltip only for dev
          response.data = Object.assign(
            response.data,
            suppliersInfo.find((y: any) => y.zrNummer == response.data.zr_nr_kreditor)
          );
        }

        return response?.data;
      })
      .catch((e: any) => {
        module_utils.error('getInvoice error', commit, e, logger);
        throw e;
      });
  },

  getInvoiceMinMaxBruttoNetto({ commit, dispatch }, payload?: InvoiceSearchData) {
    return defaultBackendInvoice
      .getInvoiceMinMaxBruttoNetto(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getMinMaxBruttoNetto response :>> ', response);
        commit('setInvoiceMinMaxBruttoNetto', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getInvoiceMinMaxBruttoNetto error', commit, e, logger);
        throw e;
      });
  },
  getInvoiceDocumentTypes({ commit, dispatch }, payload?: InvoiceSearchData) {
    return defaultBackendInvoice
      .getInvoiceDocumentTypes(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getInvoiceDocumentTypes response :>> ', response);
        commit('setInvoiceDocumentType', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getInvoiceDocumentTypes error', commit, e, logger);
        throw e;
      });
  },
  getInvoiceMembers({ commit, dispatch }, payload?: InvoiceSearchData) {
    return defaultBackendInvoice
      .getInvoiceMembers(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('setInvoiceMembers response :>> ', response);
        commit('setInvoiceMembers', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getInvoiceMembers error', commit, e, logger);
        throw e;
      });
  },
  getInvoiceSuppliers({ commit, dispatch }, payload?: InvoiceSearchData) {
    return defaultBackendInvoice
      .getInvoiceSuppliers(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('setInvoiceSuppliers response :>> ', response);
        // commit('setInvoiceSuppliers', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getInvoiceSuppliers error', commit, e, logger);
        throw e;
      });
    },
    getInvoicesForVoucher({ commit, dispatch }, payload?: any) {
    commit('setInvoiceVoucherIsLoading', true);
    return defaultBackendInvoice
      .getInvoicesForVoucher(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getInvoicesForVoucher response :>> ', response);
        commit('setInvoiceVoucherIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setInvoiceVoucherIsLoading', false);
        module_utils.error('getInvoicesForVoucher error', commit, e, logger);
        throw e;
      });
  },

  updateInvoice({ commit, dispatch }, file: Invoice) {
    return defaultBackendInvoice
      .updateInvoice(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Invoice;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.invoice_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('updateInvoice error', commit, e, logger);
        throw e;
      });
  },
  deleteInvoice({ commit, dispatch }, id: string) {
    return defaultBackendInvoice
      .deleteInvoice(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Invoice;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('deleteInvoice error', commit, e, logger);
        throw e;
      });
  },

  updateInvoiceSearchData({ commit, dispatch }, data: any) {
    console.log('------------------updateInvoiceSearchData------------>>>:   ', data);
    commit('setInvoiceSearchData', data);
  },
  updateInitialLocalStorageInvoiceSearchData({ commit, dispatch }, data: any) {
    commit('setInitialLocalStorageInvoiceSearchData', data);
  },

  resetInvoiceSearchFormData({ commit, dispatch }, data: any) {
    commit('resetInvoiceSearchFormData', data);
  },

  getInvoicesExcelReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    let searchParams = payload?.searchParams ?? getters.getInvoicesSearchParams;
    commit('setInvoiceExcelIsLoading', true);
    return defaultBackendInvoice
      .getInvoicesExcelReport(payload, searchParams)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        commit('setInvoiceExcelIsLoading', false);
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setInvoiceExcelIsLoading', false);
        module_utils.error('getInvoicesExcelReport error', commit, e, logger);
        throw e;
      });
  },
  getInvoicesPdfReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    console.log(
      '------------getInvoicesPdfReport-----------------------  >>>:payload?.searchParams  777777 ',
      payload,
      '    ',
      payload?.searchParams
    );
    let searchParams = payload?.searchParams ?? getters.getInvoicesSearchParams;
    console.log('-----------------getInvoicesPdfReport------------------  >>>:searchParams 8888888   ', searchParams);
    commit('setInvoicePdfIsLoading', true);
    return defaultBackendInvoice
      .getInvoicesPdfReport(payload, searchParams)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        commit('setInvoicePdfIsLoading', false);
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setInvoicePdfIsLoading', false);
        module_utils.error('getInvoicesPdfReport error', commit, e, logger);
        throw e;
      });
  },
  getInvoicesDetailsPdfReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendInvoice
      .getInvoicesDetailsPdfReport(payload)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getInvoicesDetailsPdfReport error', commit, e, logger);
        throw e;
      });
  },

  updateInvoiceSuppliers({ commit, dispatch }, data: any) {
    commit('setInvoiceSuppliers', data);
  },
  updateIsUseArchiveInvoices({ commit, dispatch }, data: any) {
    commit('setIsUseArchiveInvoices', data);
  },
};
