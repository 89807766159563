import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email, confirmed, regex, min, max, ext, min_value, size } from 'vee-validate/dist/rules';
import validationMessagesDE from 'vee-validate/dist/locale/de';
import validationMessagesEN from 'vee-validate/dist/locale/en';
import i18n from '../i18n';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

localize('en', validationMessagesEN);
localize('de', validationMessagesDE);
localize(i18n.messages); // enable custom messages (merge in locale), otherwise will see just "{Field} ist ungültig."

// built-in validators (in Vee-validate)
extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('regex', regex);
extend('min', min);
extend('max', max);
extend('ext', ext);
extend('size', size);
extend('min_value', min_value);

extend('voucher_umbuchung_rule', {
  validate: (value: any, args: any) => {
    if (value == args[0]) {
      return false;
    }
    return true;
  },
});

extend('voucher_betrag_rule', {
  validate: (value: any, args: any) => {
    if (value == null || value == undefined) {
      value = 0;
    }
    if (args[0] == null || args[0] == undefined) {
      args[0] = 0;
    }
    let formatAmount = value.toString().indexOf(',') != -1 ? value.replace(',', '.') : value;
    if (formatAmount.toString().indexOf('.') !== -1) {
      let splitted = formatAmount.toString().split('.');
      if (splitted.length > 1) {
        let last = splitted.pop();
        formatAmount = splitted.join('') + '.' + last;
      }
      if (formatAmount.endsWith('.')) {
        formatAmount = formatAmount.slice(0, -1);
      }
    }

    if (+formatAmount > Math.abs(+args[0])) {
      return false;
    }
    return true;
  },
});

extend('voucher_tax_rate_rule', {
  validate: (value: any, args: any) => {
    if (value == null || value == undefined) {
      value = 0;
    }
    if (args[0] == null || args[0] == undefined) {
      args[0] = 0;
    }
    let formatAmount = value.toString().indexOf(',') != -1 ? value.replace(',', '.') : value;
    if (formatAmount.toString().indexOf('.') !== -1) {
      let splitted = formatAmount.toString().split('.');
      if (splitted.length > 1) {
        let last = splitted.pop();
        formatAmount = splitted.join('') + '.' + last;
      }
      if (formatAmount.endsWith('.')) {
        formatAmount = formatAmount.slice(0, -1);
      }
    }

    // console.log('    voucher_tax_rate_rule +formatAmount',+formatAmount );
    // console.log('    voucher_tax_rate_rule value',value );
    if (+formatAmount != 19 && +formatAmount != 20 && +formatAmount != 21 && +formatAmount != 0) {
      return false;
    }
    return true;
  },
});

extend('referencevoucher_tax_rate_rule', {
  validate: (value: any, args: any) => {
    if (value == null || value == undefined) {
      value = 0;
    }
    if (args[0] == null || args[0] == undefined) {
      args[0] = 0;
    }
    if (value == 0 && +args[0] == 0) {
      return true;
    }
    let formatAmount = value.toString().indexOf(',') != -1 ? value.replace(',', '.') : value;
    if (formatAmount.toString().indexOf('.') !== -1) {
      let splitted = formatAmount.toString().split('.');
      if (splitted.length > 1) {
        let last = splitted.pop();
        formatAmount = splitted.join('') + '.' + last;
      }
      if (formatAmount.endsWith('.')) {
        formatAmount = formatAmount.slice(0, -1);
      }
    }

    if (+formatAmount > 50) {
      return false;
    }

    if (args[0] <= 19) {
      if (+formatAmount <= 19) {
        return true;
      }
    }
    if (args[0] == 20) {
      if (+formatAmount < 19 || +formatAmount == 20) {
        return true;
      }
    }
    if (args[0] == 21) {
      if (+formatAmount < 19 || +formatAmount == 21) {
        return true;
      }
    }

    return false;
  },
});

extend('max_tax_rate_rule', {
  validate: (value: any) => {
    if (value == null || value == undefined) {
      value = 0;
    }
    if (value == 0) {
      return true;
    }
    let formatAmount = value.toString().indexOf(',') != -1 ? value.replace(',', '.') : value;
    if (formatAmount.toString().indexOf('.') !== -1) {
      let splitted = formatAmount.toString().split('.');
      if (splitted.length > 1) {
        let last = splitted.pop();
        formatAmount = splitted.join('') + '.' + last;
      }
      if (formatAmount.endsWith('.')) {
        formatAmount = formatAmount.slice(0, -1);
      }
    }

    if (+formatAmount >= 50) {
      return false;
    }

    return true;
  },
});

extend('checking_AeeType_rule', {
  validate: (value: any) => {
    if (value == null || value == undefined) {
      value = 0;
    }
    if (value == -1) {
      return false;
    }

    return true;
  },
});

